import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="auto-submit"
export default class extends Controller {
  static values = {
    debounceDelay: { type: Number, default: 150 },
  };

  initialize() {
    this.debouncedSubmit = this.debounce(
      this.submitForm.bind(this),
      this.debounceDelayValue,
    );
  }

  submit() {
    this.debouncedSubmit();
  }

  submitForm() {
    this.element.requestSubmit();
  }

  debounce(callback, delay) {
    let timeout;

    return (...args) => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        callback(...args);
      }, delay);
    };
  }
}
