import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.startTime = new Date();
    this.duration = 4000;
    this.timeLeft = this.duration;

    this.updateProgressBar();

    this.timerId = setInterval(() => {
      this.timeLeft = this.duration - (new Date() - this.startTime);
      if (this.timeLeft <= 0) {
        this.element.remove();
        clearInterval(this.timerId);
      } else {
        this.updateProgressBar();
      }
    }, 50);

    this.element.addEventListener("mouseover", this.pauseTimer.bind(this));
    this.element.addEventListener("mouseout", this.resumeTimer.bind(this));
  }

  disconnect() {
    this.element.removeEventListener("mouseover", this.pauseTimer);
    this.element.removeEventListener("mouseout", this.resumeTimer);
  }

  updateProgressBar() {
    const progressBar = this.element.querySelector(".progress-bar");
    const progress = (this.timeLeft / this.duration) * 100; // Inversé ici
    progressBar.style.width = `${progress}%`;
  }

  pauseTimer() {
    clearInterval(this.timerId);
    this.timeRemaining = this.timeLeft;
  }

  resumeTimer() {
    this.startTime = new Date() - (this.duration - this.timeRemaining);
    this.timeLeft = this.timeRemaining;
    this.timerId = setInterval(() => {
      this.timeLeft = this.duration - (new Date() - this.startTime);
      if (this.timeLeft <= 0) {
        this.element.remove();
        clearInterval(this.timerId);
      } else {
        this.updateProgressBar();
      }
    }, 50);
  }
}
