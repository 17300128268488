import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="fake-notifications"
export default class extends Controller {
  static targets = ["toast", "container"];
  toastIndex = 0;
  delayBeforeStart = 5000;

  connect() {
    if (this.isMobile()) {
      this.duration = 4000; // Durée d'affichage de chaque toast en millisecondes pour les mobiles
      this.interval = 2000; // Intervalle entre chaque toast en millisecondes pour les mobiles
    } else {
      this.duration = 10000; // Durée d'affichage de chaque toast en millisecondes pour les bureaux
      this.interval = 3000; // Intervalle entre chaque toast en millisecondes pour les bureaux
    }

    setTimeout(() => {
      this.startToasting();
    }, this.delayBeforeStart);
  }

  isMobile() {
    const mobileWidth = 640;
    return window.innerWidth <= mobileWidth;
  }

  disconnect() {
    this.cleanup();
  }

  startToasting() {
    this.showNextToast();
    this.toastInterval = setInterval(() => {
      this.showNextToast();
    }, this.interval);
  }

  showNextToast() {
    const currentToast = this.toastTargets[this.toastIndex];
    if (!currentToast) {
      clearInterval(this.toastInterval);
      return;
    }
    this.showToast(currentToast);
    this.toastIndex++;
  }

  showToast(toast) {
    toast.classList.remove("hidden");
    toast.classList.add(
      "flex",
      "motion-safe:animate-mobile_slide_in",
      "md:motion-safe:animate-desktop_slide_in",
    );
    this.containerTarget.classList.add("fixed");
    this.containerTarget.prepend(toast); // Ajoute le toast au début du conteneur
    const isLastToast = this.toastIndex === this.toastTargets.length - 1; // Vérifie si c'est le dernier toast

    toast.addEventListener(
      "animationend",
      () => {
        setTimeout(() => this.hideToast(toast, isLastToast), this.duration);
      },
      { once: true },
    );
  }

  hideToast(toast, isLastToast) {
    toast.classList.remove(
      "flex",
      "motion-safe:animate-mobile_slide_in",
      "md:motion-safe:animate-desktop_slide_in",
    );
    toast.classList.add("motion-safe:animate-fade_out");
    toast.addEventListener(
      "animationend",
      () => {
        toast.classList.add("hidden");
        if (isLastToast) {
          this.containerTarget.remove();
        }
      },
      { once: true },
    );
  }

  cleanup() {
    clearInterval(this.toastInterval);
    this.toastTargets.forEach((toast) => toast.classList.add("hidden"));
  }
}
